<template>
<div>
  会议资料管理
</div>
</template>

<script>
export default {
  name:'MeetingMaterial',
  data(){
    return{

    }
  }
}
</script>

<style lang="scss">

</style>